import React, { Component } from "react"
import { Container } from "reactstrap"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import {
  Banner,
  PaddingWrapper,
  ReferencesFull,
  TitleH2Center,
} from "@igloonet-web/shared-ui"

import logoSA from "../../images/reference/student-agency/logoSA.png"
import logoSZ from "../../images/reference/snowboard-zezula/logo-sz.png"
import logocontours from "../../images/reference/contours/logocontours.png"
import logofekt from "../../images/reference/fektvut/logofekt.png"
import logofsi from "../../images/reference/fsivut/logofsi.png"
import logohelcel from "../../images/reference/helcel/logohelcel.png"
import logohvezdarna from "../../images/reference/hvezdarna/logohvezdarna2.png"
import logoife from "../../images/reference/ife/logoife.png"
import logojena from "../../images/reference/jena/logojena.png"
import logokiwi from "../../images/reference/kiwi/logo.png"
import logoldf from "../../images/reference/ldf/logoldf.svg"
import logomd from "../../images/reference/moravske-drevostavby/logo-moravskedrevostavby.png"
import logomupo from "../../images/reference/mupo/logomupo.png"
import logoplaneta from "../../images/reference/planetaher/logo-planeta.png"
import logoskinners from "../../images/reference/skinners/logo-black.png"
import logosynthon from "../../images/reference/synthon/logosynthon.png"
import logogrohe from "../../images/reference/grohe/logo.png"
import logoHannah from "../../images/reference/hannah/logo.png"
import logoSib from "../../images/reference/study-in-brno/logo.svg"
import logoSmartLashes from "../../images/reference/smart-lashes/logo.png"

class ReferenceMarketing extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const marketingReferences = [
      {
        href: "/marketing/reference/kiwi",
        image: logokiwi,
        title: "kiwi.com",
        name: "Kiwi.com",
      },
      {
        href: "/marketing/reference/fsivut",
        image: logofsi,
        title: "logo",
        name: "FSI VUT",
      },
      {
        href: "/marketing/reference/helcel",
        image: logohelcel,
        title: "logohelcel",
        name: "HELCEL",
      },
      {
        href: "/marketing/reference/ife",
        image: logoife,
        title: "ref",
        name: "IFE-CR",
      },
      {
        href: "/marketing/reference/hannah",
        image: logoHannah,
        title: "ref",
        name: "Hannah",
      },
      {
        href: "/marketing/reference/planetaher",
        image: logoplaneta,
        title: "planetaher.cz",
        name: "Planetaher.cz",
      },
      {
        href: "/marketing/reference/grohe",
        image: logogrohe,
        title: "ref",
        name: "Grohe",
      },
      {
        href: "/marketing/reference/study-in-brno",
        image: logoSib,
        title: "study-in-brno.cz",
        name: "Study in Brno",
      },
      {
        href: "/marketing/reference/skinners",
        image: logoskinners,
        alt: "ref",
        name: "SKINNERS",
      },
      {
        href: "/marketing/reference/jena",
        image: logojena,
        title: "jena.cz",
        name: "JENA",
      },
      {
        href: "/marketing/reference/snowboard-zezula",
        image: logoSZ,
        title: "snowboard-zezula.cz",
        name: "SNOWBOARD ZEZULA",
      },
      {
        href: "/marketing/reference/mupo",
        image: logomupo,
        title: "mupo.cz",
        name: "MÜPO",
      },
      {
        href: "/marketing/reference/student-agency",
        image: logoSA,
        title: "student-agency.cz",
        name: "Student Agency",
      },
      {
        href: "/marketing/reference/smart-lashes",
        image: logoSmartLashes,
        title: "smartlashes.cz",
        name: "Smart Lashes",
      },
      {
        href: "/marketing/reference/hvezdarna-a-planetarium-brno",
        image: logohvezdarna,
        title: "hvezdarna.cz",
        name: "Hvězdárna a Planetárium Brno",
      },
      {
        href: "/marketing/reference/moravske-drevostavby",
        image: logomd,
        title: "moravske-drevostavby.cz",
        name: "MORAVSKÉ DŘEVOSTAVBY",
      },
      {
        href: "/marketing/reference/contours",
        image: logocontours,
        title: "ref",
        name: "Contours",
      },
      {
        href: "/marketing/reference/ldf-mendelu",
        image: logoldf,
        title: "ref",
        name: "LDF MENDELU",
      },
      {
        href: "/marketing/reference/fektvut",
        image: logofekt,
        title: "ref",
        name: "FEKT VUT",
      },
      {
        href: "/marketing/reference/synthon",
        image: logosynthon,
        title: "ref",
        name: "Synthon",
      },
    ]

    return (
      <Layout>
        <Helmet>
          <title>Marketingové reference | igloonet</title>
          <meta
            name="description"
            content="Reference na zajímavé marketingové zakázky, které jsme realizovali pro naše klienty."
          />
        </Helmet>
        <Banner>
          <h1>Reference na marketing</h1>
          <p>Připojte se k našim spokojeným zákazníkům</p>
        </Banner>

        <Container>
          <PaddingWrapper>
            <TitleH2Center>Na čem v současné době pracujeme?</TitleH2Center>
            <p className={"text-center mb-5"}>
              V on-line marketingu se soustředíme na dlouhodobou spolupráci v
              oblasti celkové strategie. Nebráníme se však ani dílčím zakázkám.
            </p>

            <ReferencesFull anotherReferences={marketingReferences} />
          </PaddingWrapper>
        </Container>
      </Layout>
    )
  }
}

export default ReferenceMarketing
